import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';
import { DataService } from '../../services/data.service';
import {p_model} from './model';
import {post_model} from './model';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';

@Component({
  selector: 'product-ratio',
  templateUrl: './add_ratio.html',
  styleUrls: ['./add_ratio.css']
})
export class Product_ratioComponent implements OnInit {
 

 
   loginForm: FormGroup;
   isclicked : boolean= false;
   arrayOfraw: any[] = [];
   arrayOfall: any[] = [];
   arrayOfelse : any[] = [];
   modal_701 : any;
    s : any = {"k": "P"};
   ff : number = 0;
    prs = new p_model('w','c',1,1,'');
    pra : p_model[] = [];
    pos_model = new post_model('','',0,0);
    public columns: Columns[];
    public configuration: Config;
    public data : any [];
    public clicked: string;
  
  constructor(private fb: FormBuilder, private router: Router , private ds: DataService ) {   }
  ngOnInit()  {

    //  this.get_raw_mat();
     // this.get_made_by();
     this.modal_701 = document.getElementById("myModal_501");
     this.get_all_products();
     this.get_all_ratios();

     this.columns = [
      { key: 'ID', title: 'ID' },
      { key: 'RAW_NAME', title: 'Raw Product' },
      { key: 'FINAL_PRO_NAME', title: 'Final Product' },
      { key: 'RATIO', title: 'Ratio' },
      
    ];
    this.configuration = { ...DefaultConfig };
    this.data = null


  }
  get_all_ratios()
  {
    this.ds.get_all_ratios()
    .subscribe((jsonData) => { this.get_ratio_list(jsonData)
                    },(err) => console.error(err),
                   
                    );
  }
   get_all_products()
 {
     this.ds.get_all_products()
    .subscribe((jsonData) => { this.get_product_list(jsonData)
                    },(err) => console.error(err),
                   
                    );
 }
 get_ratio_list(jas:any)
 {
  console.log(jas)

   this.data = jas;
 }

 open_modal_unit()
 {
   
   this.modal_701.style.display = "block";
 }

 close_modal_unit()
  {
   this.modal_701.style.display = "none";
  }

  del_it()
  {
    this.ds.del_a_ratio_entry(this.pos_model)
    .subscribe((jsonData) => { this.get_del_a_ratio_entry(jsonData)
                    },(err) => console.error(err),
                   
                    );

    
  }

  get_del_a_ratio_entry(js:any)
  {
    console.log('ja',js);

    alert(js.msg);
    this.modal_701.style.display = "none";


  }

 get_product_list(js:any)
{
   console.log(js)
   this.arrayOfall = js;
   this.arrayOfraw = this.arrayOfall.filter(xy=>xy.TYPE_ === 'Raw Materials');
   this.arrayOfelse = this.arrayOfall.filter(xy=>xy.TYPE_ != 'Raw Materials');
  
}

eventEmitted($event: { event: string; value: any }): void {
  this.clicked = JSON.stringify($event);
  // tslint:disable-next-line:no-console
  console.log('$event', $event);

  this.pos_model.Ratio = $event.value.row.RATIO;
  this.pos_model.ID = $event.value.row.ID;

  this.modal_701.style.display = "block";


}

 

  

  

  blur_1(item_na:any)
 
 {
this.pos_model.Raw_name = item_na

  }
  blur_2(item_n:any)
 
 {
   this.pos_model.Product_name = item_n

 }

blur_3(item_e:any)
{
  this.pos_model.Ratio = item_e
}
add_ratio()
{
   this.ds.post_product_ratio(this.pos_model)
  .subscribe((jsonData) => { this.getjson33(jsonData)
                      },(err) => console.error(err),
                      
                      );
  
}

   getjson33(json :any)
  {
   console.log('RES',json.msg);
      alert(json.msg) ;
   

    
  }



  }

