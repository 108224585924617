import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
@Component({
  selector: 'app-add-product',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class Add_productComponent implements OnInit {

 
  modal_6 : any;
  modal_7 : any;
  cats :any[] = ['nos'];
  product_add_Form : FormGroup;
  productForm: FormGroup;
  Unit_Form : FormGroup;
  snak_msg : string = '';
  d_ : number = 0;
  units :  any[] = [];
  types :  any[] = ['Raw Materials','Finished','Outsourced','Waste'];
  constructor(private ds : DataService ,private fb : FormBuilder ) {

    
   }  
 

 

  ngOnInit(): void {
    this.modal_6 = document.getElementById("myModal_6");
    this.modal_7 = document.getElementById("myModal_7");
    this.productForm = this.fb.group({        
        mrp : 0,
        barcode : null,      
        description : null,
        opening_stock : 0,       
        price: 0,
        tax:0,
        net_price :0,
        type:'Raw Materials',
        unit:['Nos']  
        
      
      });
      this.product_add_Form = this.fb.group({ 

        cat_name : ['']
      
      })
      this.Unit_Form = this.fb.group({ 

        unit_name : ['']
      
      })

      this.get_all_units()
      this.get_all_category()
      
  }

  add_product()
  {
    this.ds.add_product(this.productForm.value)
    .subscribe((jsonData) => { this.get_res_pro(jsonData)
                    },(err) => console.error(err),
                   
                    );
      
  }
  open_modal()
  {
    
    this.modal_6.style.display = "block";
  }

  open_modal_unit()
  {
    
    this.modal_7.style.display = "block";
  }
  close_modal_unit()
  {
   this.modal_7.style.display = "none";
  }
  close_modal()
 {
  this.modal_6.style.display = "none";
 }
 add_cat()
 {
   this.ds.add_product_category(this.product_add_Form.value)
   .subscribe((jsonData) => { this.get_res_cat(jsonData)
                   },(err) => console.error(err),
                  
                   );
 }
 get_res_cat(json:any)
 {  
    alert(json.msg);
    this.get_all_category();

 }
 get_res_pro(json:any)
 {
   
  alert(json.msg);
 
  if(json.success == true)
  {
    this.productForm.reset();
  }

  
  
 }

 get_all_category()
 {
   this.ds.get_product_category()
   .subscribe((jsonData) => { this.get_res_cat_list(jsonData)
                   },(err) => console.error(err),
                  
                   );

 }
 get_res_cat_list(json:any)
 {
   
   for(var jp=0; jp<json.length;jp++)
   {
     this.cats[jp] = json[jp].CATEGORY_NAME

   }
   
  }

  add_unit() 
  {
    
   this.ds.add_unit(this.Unit_Form.value)
   .subscribe((jsonData) => { this.get_res_unit(jsonData)
                   },(err) => console.error(err),
                  
                   );
  }
  get_res_unit(json:any)
  {
    alert(json.msg);
    this.get_all_units();
  }



 cal_price()
 {
   this.d_ = this.productForm.controls.net_price.value * 100 / (100 + this.productForm.controls.tax.value);
   this.productForm.patchValue({price : this.d_ });
 }

 get_all_units()
 {
 	  this.ds.get_all_units()
    .subscribe((jsonData) => { this.get_units_list(jsonData)
                    },(err) => console.error(err),
                   
                    );
 }


get_units_list(js:any)
{
	  for(var jsp=0; jsp<js.length;jsp++)
    {
      this.units[jsp] = js[jsp].UNIT_NAME

    }
    
}

}

