<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#/home">Home</a></li>
    <li class="breadcrumb-item"><a href="#/home">Product</a></li>
    <li class="breadcrumb-item active" aria-current="page">Add Ratio Settings</li>
  </ol>
</nav>



<section style="width:100%;
height: 100%;
color: black;
padding: 20px;
background-color: #b5b1b7;">

    <div class="row">
  <div class="form-group col-md-6 col-sm-6 col-lg-4 col-xs-12">
    <label >Select A Raw Material</label>
      <input ngui-auto-complete #autos class="form-control"  [source]="arrayOfraw"  
            placeholder="Product Search"    
              (blur)="blur_1(autos.value)"
              list-formatter="(BAR_CODE) DESCRIPTION"
              display-property-name="DESCRIPTION">
   <!--  <small [hidden]="productForm.controls.description.valid" class="text-danger"> 
       Description is required. -                                       </small>-->

  </div>

    <div class="form-group col-md-6 col-sm-6 col-lg-4 col-xs-12">
    <label >Select A Product</label>


      <input ngui-auto-complete #autos2 class="form-control"  [source]="arrayOfelse"  
               placeholder="Product Search"    
              (blur)="blur_2(autos2.value)"
              list-formatter="(BAR_CODE) DESCRIPTION"
              display-property-name="DESCRIPTION">
            </div>

    <div class="form-group col-md-6 col-sm-6 col-lg-4 col-xs-12">
    <label >Ratio</label>

      <input  #autos3 class="form-control"   
               placeholder="Ratio"    
              (blur)="blur_3(autos3.value)">
            </div>

              <div class="form-group col-md-6 col-sm-6 col-lg-4 col-xs-12">
                <button class="btn btn-primary" (click)="add_ratio()" type="button"><span class="glyphicon glyphicon-ok"></span> Done</button>
              </div>
            </div>


</section>

<section style="    width: 100%;
height: 50px;
padding-top: 10px;
text-align: center;
font-size: 20px;
font-weight: bold;
border-bottom: 1px solid;"> Ratio list to Stock </section>



<div class="container">
  <div class="columns">
    <div class="column col-12">
      <small>Click cell to select item</small>
    </div>
  </div>
  <div class="columns">
    <div class="column col-12">
      <div id="eventArea">
        {{ clicked }}
      </div>
    </div>
  </div>
  <div class="columns">
    <div class="column col-12">
      <ngx-table
        [configuration]="configuration"
        [data]="data"
        [columns]="columns"
        (event)="eventEmitted($event)"
      >
      </ngx-table>
    </div>
  </div>
</div>



<div id="myModal_501" class="modal">
  
  
  <!-- Modal content -->
  <div class="modal-content">

    



                  <section class="container-fluid" style="width: 250px;height: 100px;background-color:#e9ecef;">
                   
                  
                    <label> Entry No &nbsp; {{this.pos_model.ID}}  </label>  <br>

                  <button class="btn btn-primary pull-right" (click)="del_it()" type="button"><span class="glyphicon glyphicon-ok"></span> Delete
                  </button>

                  <button style="margin-left: 10px;" type="button" class="btn btn-primary" (click)="close_modal_unit()">
                    Close
                  </button>
                  
                  </section> 

                 
</div>
</div>




  
  

  
