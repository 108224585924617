import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { NgEasyValidationModule } from 'ng-easy-validation';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { TableModule } from 'ngx-easy-table';

import { HeaderComponent } from './header/header';
import { HomeComponent } from './home/home';
import { FooterComponent } from './footer/footer';
import{  Aboutcomponent } from './about/about';
import{  Contactcomponent } from './contact/contact';
import { LoginComponent } from './login/login';
import { InvoiceComponent } from './invoice/new/main';
import { BillheadComponent } from './componets/bill_head/bill_head';
import { Add_productComponent } from './componets/add_product/main';
import { Update_productComponent } from './componets/update_product/main';
import { PrinteightyComponent } from './componets/print/main';
import { ViewbillsComponent } from './invoice/view/view';
import { EditbillsComponent } from './invoice/edit/edit';
import { Admin_main_page_Component } from './admin/main-page/main';
import { ReportmainComponent } from './reports/main/main';
import { ReportsalesComponent } from './reports/sales/sales';
import { ReportcollectComponent } from './reports/collection/main';
import { PurchaseComponent } from './purchase/New/main';
import { PurchaseheadComponent } from './componets/purchase_head/main';
import { ViewPurchaseallComponent } from './purchase/View/main';
import { ViewexpenseComponent } from './expense/View/main';
import { PurchaseUpdateComponent  } from './purchase/Update/main';
import { Purchase_update_headComponent } from './componets/purchase_head_update/main';
import { ReportpurchaseComponent } from './reports/purchase/main';
import { Expense_new_ } from './expense/New/main';
import { Expense_update_} from './expense/Update/main';
import { TestComponent2 } from './test/test2';
import { BillheadComponent_update } from './componets/bill_head_update/main';
import { Product_ratioComponent } from './production/ratio/add_ratio';
import { DataService } from './services/data.service';
import { MessageService } from './services/message.service';
import { HttpErrorHandler }  from './services/http-error-handler';



import { AuthGuard } from './services/auth.guard';


@NgModule({
  declarations: [
    AppComponent,LoginComponent,InvoiceComponent,
    Add_productComponent,Admin_main_page_Component,BillheadComponent_update,
    HomeComponent,HeaderComponent,FooterComponent,PurchaseComponent,PurchaseUpdateComponent,ViewexpenseComponent,
    BillheadComponent,TestComponent2,Update_productComponent,ViewPurchaseallComponent,ReportpurchaseComponent,
    Aboutcomponent,PrinteightyComponent,ReportsalesComponent,PurchaseheadComponent,Purchase_update_headComponent,
    Contactcomponent,ViewbillsComponent,EditbillsComponent,ReportmainComponent,ReportcollectComponent,
    Expense_new_,Expense_update_,Product_ratioComponent
  ],
  imports: [
    BrowserModule,HttpClientModule,NgEasyValidationModule,
    FormsModule,ReactiveFormsModule,TableModule,KeyboardShortcutsModule.forRoot(),
    AppRoutingModule,BrowserAnimationsModule,AngularMyDatePickerModule,NguiAutoCompleteModule 
  ],
  providers: [MessageService, HttpErrorHandler,DataService,AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
