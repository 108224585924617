export class p_model 
{
constructor(
          
          public PRODUCT_NAME: string,
          public HSN_CODE: string,
          public NET_PRICE: number,
          public AVAIL_QTY : number,
          public UNIT : string
      
         
          
) { }
}


export class post_model 
{
constructor(
          
          public Raw_name: string,
          public Product_name : string,
          public Ratio : number,
          public ID : number
      
         
          
) { }
}


