import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home';
import{ Aboutcomponent} from './about/about';
import { InvoiceComponent } from './invoice/new/main';
import{ Contactcomponent } from './contact/contact';
import { LoginComponent } from './login/login';
import { TestComponent2 } from './test/test2';
import { Update_productComponent } from './componets/update_product/main';
import { Add_productComponent } from './componets/add_product/main';
import { ViewbillsComponent } from './invoice/view/view';
import { EditbillsComponent } from './invoice/edit/edit';
import { Admin_main_page_Component } from './admin/main-page/main';
import { ReportmainComponent } from './reports/main/main';
import { ReportsalesComponent } from './reports/sales/sales';
import { ReportcollectComponent } from './reports/collection/main';
import { AuthGuard } from './services/auth.guard';
import { PurchaseComponent } from './purchase/New/main';
import { ViewPurchaseallComponent } from './purchase/View/main';
import { PurchaseUpdateComponent  } from './purchase/Update/main';
import { ReportpurchaseComponent } from './reports/purchase/main';
import { ViewexpenseComponent } from './expense/View/main';
import { Expense_new_ } from './expense/New/main';
import { Expense_update_} from './expense/Update/main';
import { Product_ratioComponent } from './production/ratio/add_ratio';
const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'home', component: HomeComponent,canActivate:[AuthGuard] },
  { path: 'contact',component:Contactcomponent,canActivate:[AuthGuard] },
  { path: 'new_invoice',component: InvoiceComponent,canActivate:[AuthGuard] },
  { path: 'admin_main_page',component: Admin_main_page_Component,canActivate:[AuthGuard] },
  { path: 'product-update',component: Update_productComponent,canActivate:[AuthGuard] },
  { path: 'add-product',component: Add_productComponent,canActivate:[AuthGuard] },
  { path: '_test2',component: TestComponent2,canActivate:[AuthGuard] },
  { path: 'view-bills',component: ViewbillsComponent,canActivate:[AuthGuard] },
  { path: 'edit-bill',component: EditbillsComponent,canActivate:[AuthGuard] },
  { path: 'report-main',component:  ReportmainComponent,canActivate:[AuthGuard] },
  { path: 'report-sales',component:  ReportsalesComponent,canActivate:[AuthGuard] },
  { path: 'report-purchase',component:  ReportpurchaseComponent,canActivate:[AuthGuard] },
  { path: 'collection-report',component:  ReportcollectComponent,canActivate:[AuthGuard] },  
  { path: 'purchase-new',component:  PurchaseComponent,canActivate:[AuthGuard] },
  { path: 'purchase-view-all',component:  ViewPurchaseallComponent,canActivate:[AuthGuard] },
  { path: 'purchase-update',component:  PurchaseUpdateComponent,canActivate:[AuthGuard] },
  { path: 'expense-new',component:  Expense_new_,canActivate:[AuthGuard] },
  { path: 'expense-view-all',component:  ViewexpenseComponent,canActivate:[AuthGuard] },
  { path: 'edit-expenses',component:  Expense_update_,canActivate:[AuthGuard] },
  { path: 'production-ratio',component:  Product_ratioComponent,canActivate:[AuthGuard] },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
