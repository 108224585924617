<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#/home">Home</a></li>
    <li class="breadcrumb-item"><a href="#/home">Product</a></li>
    <li class="breadcrumb-item active" aria-current="page">Add Product</li>
  </ol>
</nav>


<section style="width:100%;
height: 100%;

color: yellow;
padding: 20px;
background-color: #7b847d;">

  <form [formGroup]="productForm"> 


    <div class="row">
  <div class="form-group col-md-6 col-sm-6 col-lg-4 col-xs-12">
    <label >Description</label>
    <input id="product_des" formControlName="description" type="text" class="form-control" placeholder="description">
    <small [hidden]="productForm.controls.description.valid" class="text-danger">
      Description is required.
                                       </small>
  </div>


  <div class="form-group col-md-4 col-sm-4 col-lg-3 col-xs-6">
        <label> Type </label>
        <select class="form-control" formControlName="type" id="type">
                                    
                                                  <option *ngFor="let type of types" [value]="type">{{type}}</option>
                                                  </select>
        
      </div>


  <div class="form-group col-md-2 col-sm-2 col-lg-2 col-xs-12">
    <label> Bar code </label>
    <input  formControlName="barcode"   type="text"  class="form-control" placeholder="bar code">
 
 </div>


        
         <div class="form-group col-md-2 col-sm-2 col-lg-2 col-xs-12">
          <label> MRP </label>
          <input  formControlName="mrp"   type="number"  class="form-control" placeholder="mrp">
       
       </div> 

          <div class="form-group col-md-2 col-sm-2 col-lg-2 col-xs-12">
            <label> Tax </label>
            <input  formControlName="tax" (keyup)="cal_price()"  type="number"  class="form-control" placeholder="Tax">
         
         </div>


          <div class="form-group col-md-2 col-sm-2 col-lg-2 col-xs-12">
            <label> Net Price </label>
            <input  formControlName="net_price" (keyup)="cal_price()"  type="number"  class="form-control" placeholder="net price">
         
         </div>  

           <div class="form-group col-md-2 col-sm-2 col-lg-2 col-xs-12">
           <label> Opening Stock </label>
           <input  formControlName="opening_stock"    type="number"  class="form-control" placeholder="Opening Stock">
           </div>      


        
         <div class="form-group col-md-2 col-sm-2 col-lg-2 col-xs-12">
            <label> Price </label>
            <input  formControlName="price" [attr.disabled]="true"  type="number"  class="form-control" placeholder="price">
         
         </div>

            
     


    
  
         
         
             
 

      
    <div class="form-group col-md-4 col-sm-4 col-lg-3 col-xs-6">
        <label> Unit </label>
        <select class="form-control" formControlName="unit" id="unit">
                                    
                                                  <option *ngFor="let unit of units" [value]="unit">{{unit}}</option>
                                                  </select>
        
      </div>



      <div  class="form-group col-md-1 col-sm-1 col-lg-1 col-xs-6">  
        <span>   <button mat-fab type="button" style="background-color: slateblue;" (click)="open_modal_unit()">
        +
      </button>  </span>
    </div>

  


  </div>


</form>


  <button class="btn btn-primary pull-right" 
  (click)="add_product()" type="button"><span class="glyphicon glyphicon-ok"></span> Done</button>


  
</section>


  
  <div id="snackbar">{{snak_msg}}</div>
  
  
  
  
  






    <div id="myModal_7" class="modal">
  
  
      <!-- Modal content -->
      <div class="modal-content">

          <label  class="btn btn-primary" >
              Add New Unit
          </label> 



                      <section class="container-fluid" style="width: 100%;height: 145px;background-color:#e9ecef;">
                        <form [formGroup]="Unit_Form">     
                              <div class="row">              
                      
                          <div class="form-group col-md-6 col-sm-6 col-lg-4 col-xs-12">
                            <label >Unit Name</label>
                            <input  formControlName="unit_name" type="text" class="form-control" placeholder="Unit name">
                            <small [hidden]="Unit_Form.controls.unit_name.valid" class="text-danger">
                              Unit name is required.
                                                              </small>
                          </div>
                      
                      
                        
                      
                      </div>
                      
                      </form>
                      
                      <button class="btn btn-primary pull-right" (click)="add_unit()" type="button"><span class="glyphicon glyphicon-ok"></span> Add
                      </button>

                      <button style="width: 150px;margin-left: 10px;" type="button" class="btn btn-primary" (click)="close_modal_unit()">
                        Cancel
                      </button>
                      
                      </section> 

                     
</div>
</div>